import { render, staticRenderFns } from "./ChangeEmail.vue?vue&type=template&id=23ee1bc8&scoped=true&"
import script from "./ChangeEmail.vue?vue&type=script&lang=js&"
export * from "./ChangeEmail.vue?vue&type=script&lang=js&"
import style0 from "./ChangeEmail.vue?vue&type=style&index=0&id=23ee1bc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ee1bc8",
  null
  
)

export default component.exports